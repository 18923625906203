import Css from "./style.module.scss";

import { Button } from "shards-react";
import { FiCreditCard, FiPlusCircle } from "react-icons/fi";
import { Page, Preloader } from "lib/common";
import {
  checkInstitutionAccountsFetching,
  checkInstitutionLinksFetching,
  checkInstitutionsFetching,
  getInstitutionLinksData
} from "selectors/institutions";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ConnectInstitutionWindow from "./lib/ConnectInstitutionWindow";
import Institution from "./lib/Institution";
import InstitutionsActions from "actions/InstitutionsActions";
import PageHeader from "lib/common/PageHeader";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import UiRoutes from "const/UiRoutes";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";
import useShowModal from "hooks/useShowModal";

const { ACCOUNTS } = UiRoutes;

const USER_CANCELLED_SESSION = "UserCancelledSession";

const AddButtonBlock = ({ disabled, onAddAccountButtonClick }) => {
  const { uiTexts } = useSelector(getTextsData);

  return (
    <div>
      <Button
        size="sm"
        disabled={disabled}
        onClick={onAddAccountButtonClick}>
        <span><FiPlusCircle /><span>{uiTexts.connectBankAccounts}</span></span>
      </Button>
    </div>
  );
};

const AccountsPage = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [{ ref: reference, error, details }] = useEnvVars();

  const { uiTexts, messages } = useSelector(getTextsData);

  const fetchingInstitutions = useSelector(checkInstitutionsFetching);

  const fetchingInstitutionAccounts = useSelector(checkInstitutionAccountsFetching);

  const fetchingInstitutionLinks = useSelector(checkInstitutionLinksFetching);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const institutionLinksData = useSelector(getInstitutionLinksData);

  const [setupInProcess, setSetupInProcess] = useState(false);

  const fetchingData = fetchingInstitutionLinks || fetchingInstitutionAccounts || fetchingInstitutions;

  const filteredInstitutionLinksData = institutionLinksData.filter((linkData) => linkData.accounts.length);

  const showCommonModal = useShowCommonModal();

  const [
    addAccountModalShown,
    showAddAccountModal,
    handleAddAccountModalClose
  ] = useShowModal();

  const handleAddAccountButtonClick = useCallback(async() => {
    const institutionCode = await showAddAccountModal();

    if (institutionCode) {
      const link = await dispatch(InstitutionsActions.getConnectingLink(institutionCode));

      if (link) window.location = link;
    }
  }, [dispatch, showAddAccountModal]);

  useLayoutEffect(() => {
    if (reference) {
      if (error) {
        if (error !== USER_CANCELLED_SESSION) {
          showCommonModal({
            size: "sm",
            text: (details || "").replaceAll("+", " "),
            headerText: uiTexts.error
          });
        }
      } else {
        setSetupInProcess(true);
        dispatch(InstitutionsActions.completeSetup(reference)).then(() => {
          history.replace(`/${selectedBusinessId}${ACCOUNTS}`);
          setSetupInProcess(false);
        });
      }
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [details, dispatch, error, history, reference, selectedBusinessId, showCommonModal, uiTexts.error]);

  useEffect(() => {
    dispatch(InstitutionsActions.fetchInstitutionAccounts());
  }, [dispatch]);

  return (
    <Page className={Css.accountsPage}>
      <PageHeader>
        {!!filteredInstitutionLinksData.length && (
          <AddButtonBlock
            disabled={fetchingData}
            onAddAccountButtonClick={handleAddAccountButtonClick} />
        )}
      </PageHeader>
      <div className={Css.institutions}>
        {filteredInstitutionLinksData.length
          ? filteredInstitutionLinksData.map((linkData) => {
            return (
              <Institution
                key={linkData.id}
                disabled={fetchingData}
                linkData={linkData} />
            );
          }) : (
            !fetchingData && !setupInProcess && (
              <div className={Css.noDataContent}>
                <div className={Css.icon}><FiCreditCard /></div>
                <h4>{messages.bankConnectionCreatingGreeting}</h4>
                <AddButtonBlock
                  disabled={fetchingData}
                  onAddAccountButtonClick={handleAddAccountButtonClick} />
              </div>
            )
          )}
      </div>
      {(fetchingData || setupInProcess) && <Preloader absolute />}
      {addAccountModalShown && (
        <ConnectInstitutionWindow onClose={handleAddAccountModalClose} />
      )}
    </Page>
  );
};

export default AccountsPage;
