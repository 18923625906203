import Css from "./style.module.scss";

import { FiCreditCard } from "react-icons/fi";
import { ModalWindow } from "lib/common";
import { getInstitutionAccountsData, getInstitutionLinksData } from "selectors/institutions";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React from "react";
import Utils from "utils/Utils";

const AccountInfoWindow = ({ id, onClose }) => {
  const { uiTexts } = useSelector(getTextsData);

  const accountsData = useSelector(getInstitutionAccountsData);

  const institutionLinksData = useSelector(getInstitutionLinksData);

  const accountData = Utils.arrayFindById(accountsData, id);

  const institutionLinkData = Utils.arrayFindById(institutionLinksData, accountData.linkId);

  const {
    name,
    currency,
    accountNumber,
    extraData: { bic, iban, bban, bankCode, details, product, ownerName } = {}
  } = accountData;

  const {
    institution: { name: institutionName }
  } = institutionLinkData;

  return (
    <ModalWindow
      config={{
        headerText: <span title={name}>{name}</span>
      }}
      className={Css.accountInfoWindow}
      iconComponent={FiCreditCard}
      onClose={onClose}>
      <div>
        <span className={Css.label}>{`${uiTexts.institution}:`}</span>
        <span>{institutionName}</span>
      </div>
      {!!bic && (
        <div>
          <span className={Css.label}>BIC:</span>
          <span>{bic}</span>
        </div>
      )}
      {iban
        ? (
          <div>
            <span className={Css.label}>IBAN:</span>
            <span>{iban}</span>
          </div>
        )
        : (!!bban && (
          <div>
            <span className={Css.label}>BBAN:</span>
            <span>{bban}</span>
          </div>
        ))}
      {!!accountNumber && (
        <div>
          <span className={Css.label}>{`${uiTexts.accountNumber}:`}</span>
          <span>{accountNumber}</span>
        </div>
      )}
      {!!bankCode && (
        <div>
          <span className={Css.label}>{`${uiTexts.bankCode}:`}</span>
          <span>{bankCode}</span>
        </div>
      )}
      {!!ownerName && (
        <div>
          <span className={Css.label}>{`${uiTexts.ownerName}:`}</span>
          <span>{ownerName}</span>
        </div>
      )}
      {!!details && (details !== currency) && (
        <div>
          <span className={Css.label}>{`${uiTexts.details}:`}</span>
          <span>{details}</span>
        </div>
      )}
      {!!product && (
        <div>
          <span className={Css.label}>{`${uiTexts.product}:`}</span>
          <span>{product}</span>
        </div>
      )}
      {!!currency && (
        <div>
          <span className={Css.label}>{`${uiTexts.currency}:`}</span>
          <span>{currency}</span>
        </div>
      )}
    </ModalWindow>
  );
};

export default React.memo(AccountInfoWindow);
