import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import fallbackLogo from "assets/creditCard.svg";

import { AutoTooltip } from "lib/common";
import { Button, Card, CardBody } from "shards-react";
import { FiCheckCircle, FiInfo, FiLoader, FiRefreshCw, FiRepeat, FiTrash2 as FiTrash } from "react-icons/fi";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AccountInfoWindow from "./lib/AccountInfoWindow";
import Constants from "const/Constants";
import ImageWithFallback from "nlib/common/ImageWithFallback";
import InstitutionLinkStatuses from "const/InstitutionLinkStatuses";
import InstitutionsActions from "actions/InstitutionsActions";
import React, { useCallback } from "react";
import TableNew, { TableCell, TableHead, TableRow } from "lib/common/TableNew";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useShowCommonModal from "hooks/useShowCommonModal";
import useShowModal from "hooks/useShowModal";

const { ACCOUNT_EXPIRATION_DAYS_WARNING } = Constants;

const stopPropagation = (event) => event.stopPropagation();

const StatusText = ({ expiresAt, expired, disconnected, expiresSoon }) => {
  const { uiTexts } = useSelector(getTextsData);

  if (expired) {
    return <span className={classNames(Css.text, Css.expired)}>{uiTexts.expired}</span>;
  }
  if (disconnected) {
    return <span className={classNames(Css.text, Css.expired)}>{uiTexts.disconnected}</span>;
  }

  return (
    <>
      <span className={classNames(Css.text, expiresSoon && Css.warning)}>
        {`${uiTexts.expires}: ${moment.duration(moment.utc(expiresAt).diff(moment.utc())).humanize(true)}`}
      </span>
      {!expiresSoon && <FiCheckCircle className={Css.successIcon} />}
    </>
  );
};

const Institution = ({ disabled, linkData }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const showCommonModal = useShowCommonModal();

  const [
    accountInfoModalShown,
    showAccountInfoModal,
    onAccountInfoModalClose
  ] = useShowModal();

  const { institution, accounts, status, expiresAt } = linkData;

  const expired = moment.utc(expiresAt).isSameOrBefore(moment.utc());

  const disconnected = status !== InstitutionLinkStatuses.ONLINE;

  const { code: institutionCode } = institution;

  const { uiTexts, messages } = useSelector(getTextsData);

  const businessId = useSelector(getSelectedBusinessId);

  const expiresSoon = !expired && !disconnected
    && moment.duration(moment.utc(expiresAt).diff(moment.utc())).asDays() < ACCOUNT_EXPIRATION_DAYS_WARNING;

  const handleReconnectAccount = useCallback(async() => {
    const result = await showCommonModal({
      text: messages.actionConfirm,
      confirm: true
    });

    if (result) {
      const link = await dispatch(InstitutionsActions.getConnectingLink(institutionCode));

      if (link) window.location = link;
    }
  }, [institutionCode, dispatch, messages, showCommonModal]);

  const handleTableAccountDelete = useCallback(async(event) => {
    const { currentTarget: { dataset: { id } } } = event;

    const result = await showCommonModal({
      text: messages.actionConfirm,
      confirm: true
    });

    if (result) {
      await dispatch(InstitutionsActions.deleteInstitutionAccount(id));
    }
  }, [dispatch, messages.actionConfirm, showCommonModal]);

  const handleTableAccountInfo = useCallback((event) => {
    const { currentTarget: { dataset: { id } } } = event;

    showAccountInfoModal(id);
  }, [showAccountInfoModal]);

  const handleAccountClick = useCallback(({ currentTarget: { dataset: { id } } }) => {
    history.push(`/${businessId}${UiRoutes.TRANSACTIONS}?accountId=${id}`);
  }, [businessId, history]);

  return (
    <>
      <Card className={Css.institution}>
        <CardBody className={Css.cardBody}>
          <div className={Css.header}>
            <div className={Css.logo}>
              <ImageWithFallback src={institution.logo} alt={institution.name} fallback={fallbackLogo} />
            </div>
            <div className={Css.name}>{institution.name}</div>
            <div className={Css.expiresAt}>
              <StatusText
                expiresAt={expiresAt}
                expiresSoon={expiresSoon}
                expired={expired}
                disconnected={disconnected} />
              {(expiresSoon || expired || disconnected) && (
                <Button
                  size="sm"
                  theme={(expired || disconnected) ? "danger" : "warning"}
                  title={expired ? uiTexts.reconnect : uiTexts.renew}
                  disabled={disabled}
                  className={Css.reconnectButton}
                  onClick={handleReconnectAccount}>
                  <FiRefreshCw />
                </Button>
              )}
            </div>
          </div>
          <TableNew theme="flat" className={Css.table}>
            <TableRow className={Css.tableHead}>
              <TableHead className={Css.nameCell}>{uiTexts.accountName}</TableHead>
              <TableHead className={Css.balanceCell}>{uiTexts.balance}</TableHead>
              <TableHead className={Css.transactionsCell}>{uiTexts.transactions}</TableHead>
              <TableHead className={Css.lastUpdatedAtCell}>{uiTexts.lastTransaction}</TableHead>
              <TableHead className={Css.lastUpdatedAtCell}>{uiTexts.lastSync}</TableHead>
              <TableHead className={Css.actionsCell} />
            </TableRow>
            {accounts.map((accountData) => {
              const {
                id: accountId,
                name,
                balance,
                currency,
                lastUpdatedAt,
                lastSyncAt,
                transactionsCount
              } = accountData;

              const balanceText = Utils.toMoneyString(balance || 0, currency);

              return (
                <TableRow
                  key={accountId}
                  className={Css.tableRow}
                  data-id={accountId}
                  onClick={handleAccountClick}>
                  <TableCell className={Css.nameCell}>
                    {name}
                  </TableCell>
                  <TableCell className={Css.balanceCell}>
                    <AutoTooltip
                      active={!!balance}
                      className={balance ? CommonCss.coloredNumber : ""}
                      data-negative={balance < 0 ? "" : undefined}>
                      {balanceText}
                    </AutoTooltip>
                  </TableCell>
                  <TableCell className={Css.transactionsCell}>
                    <FiRepeat />
                    <span>{transactionsCount}</span>
                  </TableCell>
                  <TableCell className={Css.lastUpdatedAtCell}>
                    {lastUpdatedAt
                      ? moment.utc(lastUpdatedAt).format(Constants.DATETIME_FORMATS.DATE_TEXT)
                      : Constants.EMPTY_PLACEHOLDER}
                  </TableCell>
                  <TableCell className={Css.lastUpdatedAtCell}>
                    {lastSyncAt
                      ? moment.duration(moment.utc(lastSyncAt).diff(moment.utc())).humanize(true)
                      : (
                        <>
                          <FiLoader data-wait />
                          <span>{uiTexts.inProgress}</span>
                        </>
                      )}
                  </TableCell>
                  <TableCell className={Css.actionsCell} onClick={stopPropagation}>
                    <Button
                      outline
                      size="sm"
                      theme="light"
                      title={uiTexts.accountInfo}
                      disabled={disabled}
                      className={Css.infoButton}
                      data-id={accountId}
                      onClick={handleTableAccountInfo}>
                      <FiInfo />
                    </Button>
                    <Button
                      outline
                      size="sm"
                      theme="light"
                      title={uiTexts.delete}
                      disabled={disabled}
                      className={Css.deleteButton}
                      data-id={accountId}
                      onClick={handleTableAccountDelete}>
                      <FiTrash />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableNew>
        </CardBody>
      </Card>
      {accountInfoModalShown && (
        <AccountInfoWindow
          id={accountInfoModalShown}
          onClose={onAccountInfoModalClose} />
      )}
    </>
  );
};

export default React.memo(Institution);
